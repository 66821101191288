<template>
  <div>
    <div class="card" style='display: flex;'>
      <div class="left">
        <el-button type='primary' plain @click='handleExport' icon='el-icon-upload2'>导出</el-button>
      </div>
      <div class='line'></div>
      <div class="middle">
        <el-form :model="form" :inline="true">
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="form.create_time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="标题">
            <el-input placeholder="请输入标题" v-model="form.notice_title" @keydown.enter.native="onSubmit" clearable></el-input>
          </el-form-item>
          <el-form-item label="作者">
            <el-input placeholder="请输入作者" v-model="form.author" @keydown.enter.native="onSubmit" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button  @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        stripe
        height="700"
        @row-dblclick="handleDalclick"
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              {{scope.row[item.field_alias] ? scope.row[item.field_alias] : '--'}}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDele(scope.row)" style="color: #E7541E;">删除</el-button>
            <el-button type="text" @click="handleLook(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="$store.state.pageSizes[0]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
    <select-department :dialogVisible="dialogVisible" @onHideDepartment="handleHideDepartment" @onSelect="handleSelect" />
    <el-dialog
      title="详情"
      :visible.sync="lookVisible"
      width="40%"
      :before-close="handleClose">
      <div class="list">
        <div class="item">
          <div class="item-left">公告内容：</div>
          <div class="item-right">{{detail.content || '--'}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="lookVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListReq, getDetailReq, handleDeleReq } from '@/api/work/notice';
import SelectDepartment from '../SelectDepartment.vue';

export default {
  data() {
    return {
      detail: {},
      loading: false,
      currRow: {}, // 当前行
      lookVisible: false, // 查看弹窗出现标识
      activeName: 'first',
      label: '',
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      form: {
      },
      dialogVisible: false,
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '发送范围', field_alias: 'send_user' },
        { field_text: '标题', field_alias: 'notice_title' },
        { field_text: '作者', field_alias: 'author' },
        { field_text: '发送时间', field_alias: 'create_time' },
      ],
      tableData: {
        list: [{}],
        total: 0,
      },
    };
  },
  components: {
    SelectDepartment,
  },
  created() {
    this.getList();
  },
  methods: {
    handleDalclick(row) {
      this.handleLook(row);
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    resetForm() {
      this.form = {};
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },
    // 查看详情
    getDetail(notice_id) {
      getDetailReq(notice_id)
        .then((res) => {
          if (res.code === 1) {
            this.detail = res.data;
          }
        });
    },
    getList() {
      this.loading = true;
      getListReq({ ...this.form, page: this.page, page_num: this.page_num })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 删除
    handleDele(row) {
      this.currRow = row;
      this.$confirm('确定要删除该公告吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        handleDeleReq(row.notice_id)
          .then((res) => {
            if (res.code === 1) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            }
            this.getList();
          });
      });
    },
    handleLook(row) {
      this.lookVisible = true;
      this.currRow = row;
      this.getDetail(row.notice_id);
    },
    // 弹窗关闭
    handleClose() {
      this.lookVisible = false;
    },
    // 选择部门子组件回调事件
    handleSelect(nodeList) {
      console.log(nodeList);
      if (nodeList.length === 1) {
        this.label = nodeList[0].label;
      } else {
        this.label = `${nodeList[0].label}等多个部门`;
      }
      this.form.select = 1;
    },
    handleHideDepartment() {
      this.dialogVisible = false;
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getList();
    },
    // 导出
    handleExport() {},
    // 选择部门
    handleChoose() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  .item {
    display: flex;
    margin-bottom: 20px;
    &-left {
      width: 100px;
      font-weight: bold;
    }
    &-right {
      flex: 1;
    }
  }
}
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
