import { post } from '@/utils/request';

// 获取公告列表
const getListReq = (params) => post({
  url: '/store/oa.Notice/getListForErp',
  data: {
    ...params,
  },
});

// 公告和汇报详情
const getDetailReq = (notice_id) => post({
  url: '/store/oa.Notice/getDetailForErp',
  data: {
    notice_id,
  },
});

// 删除公告
const handleDeleReq = (notice_id) => post({
  url: '/store/oa.Notice/delete',
  data: {
    notice_id,
  },
})

export {
  getListReq,
  getDetailReq,
  handleDeleReq,
};
